import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams, useNavigate } from 'react-router-dom';
import { WorkspaceContext } from '../../../contextApi/WorkspaceContext';
import { AccountContext } from '../../../contextApi/AccountContext';
import { APIs } from '../../../config';
import PopupModal from '../../../components/PopupModal';
import { Button, TextField, Box, Typography } from '@mui/material';
import RelatedCard from './components/RelatedCard';
import RuleSetSelect from './components/RuleSetSelect';
import { ReactComponent as StraightSVG } from '../../../assets/icons/Straight.svg';
import { ReactComponent as ListicleSVG } from '../../../assets/icons/Listicle.svg';
import { ReactComponent as InterviewSVG } from '../../../assets/icons/Interview.svg';
import FormatCard from './components/FormatCard';
import RelatedContentCard from './components/RelatedContentCard';
// import { DropzoneArea } from 'material-ui-dropzone'; // Assuming you're using material-ui-dropzone

export default function Create(props) {
  const { sessionJWT, getSession } = useContext(AccountContext);
  const [popupModalStatus, setPopupModalStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { workspaceId } = useParams();
  const { workspaceData, setWorkspaceData } = useContext(WorkspaceContext);
  const [currentStep, setCurrentStep] = useState(0);
  const [file, setFile] = useState(null); // State for file upload
  const [text, setText] = useState(''); // State for text input
  const [similarArticles, setSimilarArticles] = useState({
    similar: [],
    related: [],
  }); // State for similar articles
  const [selectedSimilarArticles, setSelectedSimilarArticles] = useState({
    similar: [],
    related: [],
  }); // State for related articles
  const [rulesets, setRulesets] = useState({ rulesets: [], shared: [] });
  const [selectedRulesets, setSelectedRulesets] = useState({
    rulesets: [],
    shared: [],
  });
  const [formatOptions, setFormatOptions] = useState([
    {
      title: 'straight',
      icon: <StraightSVG />,
      description: '스트레이트 기사',
      isActivated: true,
      selected: false,
    },
    {
      title: 'listicle',
      icon: <ListicleSVG />,
      description: '리스티클 기사',
      isActivated: true,
      selected: false,
    },
    {
      title: 'interview',
      icon: <InterviewSVG />,
      description: '인터뷰 기사',
      isActivated: false,
      selected: false,
    },
  ]);

  const [instructions, setInstructions] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    if (workspaceData.selectedSourceType === 'article') {
      fetchSimilarArticles();
      fetchRuleSets();
    } else {
      fetchRuleSets();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchSimilarArticles = async () => {
    try {
      const response = await fetch(
        `${APIs('sources', workspaceId)}/${workspaceData.selectedSource.id}/related`,
        {
          headers: {
            Authorization: `Bearer ${sessionJWT}`,
            'Content-Type': 'application/json',
          },
          method: 'GET',
        }
      );
      const data = await response.json();
      if (response.ok) {
        setSimilarArticles(data);
      } else {
        console.error(data.detail);
      }
    } catch (error) {
      console.error('Failed to fetch similar articles:', error);
    }
  };

  const handleCreate = () => {
    if (sessionJWT) {
      // Destructure specific keys
      const modifiedRulesets = [
        ...selectedRulesets.rulesets,
        ...selectedRulesets.shared,
      ];

      const fetchData = async () => {
        const request = await fetch(`${APIs('articles', workspaceId)}/user`, {
          headers: {
            Authorization: `Bearer ${sessionJWT}`,
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({
            headline: workspaceData.selectedSource.headline,
            thumbnail_url: workspaceData.selectedSource.thumbnail_url,
            source_url: workspaceData.selectedSource.url,
            source_type: workspaceData?.selectedSourceType,
            source_id: workspaceData?.selectedSource.id,
            related_articles: workspaceData?.additionalArticles.related,
            similar_articles: workspaceData?.additionalArticles.similar,
            rulesets: modifiedRulesets,
            format: workspaceData?.format,
          }),
        });
        const response = await request.json();
        if (request.ok) {
          window.location.reload(true);
        } else {
          openCautionPopup({
            message: response.detail,
            confirmOption: 'refresh',
          });
        }
      };
      if (workspaceData && workspaceData.selectedSource) {
        fetchData();
      } else {
        openCautionPopup({
          message: 'Please start over.',
          confirmOption: 'refresh',
        });
      }
    } else {
      openCautionPopup({
        message: 'Session Expired. Please Refresh.',
        confirmOption: 'refresh',
      });
    }
  };

  const fetchRuleSets = async () => {
    const request = await fetch(`${APIs('rulesets', workspaceId)}`, {
      headers: { Authorization: `Bearer ${sessionJWT}` },
    });
    const data = await request.json();
    if (data) {
      setRulesets(data);
    } else return;
  };

  const handleArticleSelection = (category, article) => {
    setSelectedSimilarArticles((prevState) => {
      const selectedArticles = prevState[category];
      const isSelected = selectedArticles.some((a) => a.id === article.id);
      const updatedArticles = isSelected
        ? selectedArticles.filter((a) => a.id !== article.id)
        : [...selectedArticles, article];

      return { ...prevState, [category]: updatedArticles };
    });
  };

  const handleRuleSetSelection = (category, ruleset) => {
    setSelectedRulesets((prevState) => {
      const selectedRulesets = prevState[category];
      const isSelected = selectedRulesets.some((a) => a.id === ruleset.id);
      const updatedRulesets = isSelected
        ? selectedRulesets.filter((a) => a.id !== ruleset.id)
        : [...selectedRulesets, ruleset];

      return { ...prevState, [category]: updatedRulesets };
    });
  };

  const openCautionPopup = ({ message, confirmOption, buttonName }) => {
    const confirmAction = () => {
      if (confirmOption === 'stay') {
        return () => {
          setPopupModalStatus(false);
        };
      } else if (confirmOption === 'refresh') {
        return () => {
          window.location.reload(true);
        };
      } else if (confirmOption === 'goToSignIn') {
        return () => {
          navigate('/signin');
        };
      } else {
        return confirmOption;
      }
    };
    PopupModal.closePopupModal = () => {
      setPopupModalStatus(false);
    };
    PopupModal.popupMessage =
      message || 'Sorry, something went wrong. Please contact Playbook.';
    PopupModal.confirmBtnName = buttonName || 'Confirm';
    PopupModal.handleConfirmAction = confirmAction();

    setPopupModalStatus(true);
  };

  const handleNext = () => {
    if (currentStep === 0) {
      if (workspaceData.selectedSourceType === 'doc' && file) {
        // Handle file upload
        const formData = new FormData();
        formData.append('file', file);

        fetch(`${APIs('upload', workspaceId)}`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${sessionJWT}`,
          },
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            setWorkspaceData((prevData) => ({
              ...prevData,
              docUrl: data.url,
            }));
            setCurrentStep((prevStep) => prevStep + 1);
          })
          .catch((error) => {
            console.error('Error uploading file:', error);
            openCautionPopup({
              message: 'File upload failed. Please try again.',
              confirmOption: 'stay',
            });
          });
      } else if (workspaceData.selectedSourceType === 'text' && text) {
        setWorkspaceData((prevData) => ({
          ...prevData,
          sourceText: text,
        }));
        setCurrentStep((prevStep) => prevStep + 1);
      } else if (workspaceData.selectedSourceType === 'article') {
        setWorkspaceData((prevData) => ({
          ...prevData,
          additionalArticles: selectedSimilarArticles,
        }));
        setCurrentStep((prevStep) => prevStep + 1);
      } else {
        openCautionPopup({
          message: 'Please provide the required input.',
          confirmOption: 'stay',
        });
      }
    } else if (currentStep === 1) {
      if (workspaceData.format === undefined) {
        openCautionPopup({
          message: 'Choose a format',
          confirmOption: 'stay',
        });
      } else {
        setCurrentStep((prevStep) => prevStep + 1);
      }
    } else if (currentStep === 2) {
      setWorkspaceData((prevData) => ({
        ...prevData,
        rulesets: selectedRulesets,
      }));
      setCurrentStep((prevStep) => prevStep + 1); // Move to new step
    } else if (currentStep === 3) {
      setWorkspaceData((prevData) => ({
        ...prevData,
        instructions: instructions,
      }));
      handleCreate();
      navigate(`/myworkspace/${workspaceId}/dashboard`);
    }
  };

  const handleBack = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleFormatSelection = (format) => {
    const updatedFormatOptions = formatOptions.map((option) => {
      if (option.title === format) {
        return { ...option, selected: true };
      } else {
        return { ...option, selected: false };
      }
    });

    setFormatOptions(updatedFormatOptions);

    setWorkspaceData((prevData) => ({
      ...prevData,
      format: format,
    }));
    // Proceed to the next step or handle completion
    // Assuming there's a next step, otherwise, handle completion
  };

  const renderStepContent = () => {
    if (currentStep === 0) {
      switch (workspaceData.selectedSourceType) {
        case 'article':
          return (
            <div>
              {similarArticles.similar?.length > 0 && (
                <ArticleContainer>
                  <TitleSection>유사 기사 AI에 추가</TitleSection>
                  <NewsCardContainer>
                    {similarArticles.similar.map((article) => (
                      <RelatedContentCard
                        key={article.id}
                        content={article}
                        category='similar'
                        isSelected={selectedSimilarArticles.similar.some(
                          (a) => a.id === article.id
                        )}
                        onCardClick={handleArticleSelection}
                      />
                    ))}
                  </NewsCardContainer>
                </ArticleContainer>
              )}
              {similarArticles.related?.length > 0 && (
                <ArticleContainer>
                  <TitleSection>연관 기사 AI에 추가</TitleSection>
                  <NewsCardContainer>
                    {similarArticles.related.map((article) => (
                      <RelatedContentCard
                        key={article.id}
                        content={article}
                        category='related'
                        isSelected={selectedSimilarArticles.related.some(
                          (a) => a.id === article.id
                        )}
                        onCardClick={handleArticleSelection}
                      />
                    ))}
                  </NewsCardContainer>
                </ArticleContainer>
              )}
            </div>
          );
        case 'doc':
          return (
            <Box>
              <Typography variant='h6'>Upload Document</Typography>
              {/* <DropzoneArea
                onChange={(files) => setFile(files[0])}
                acceptedFiles={['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']}
                filesLimit={1}
              /> */}
            </Box>
          );
        case 'text':
          return (
            <Box>
              <Typography variant='h6'>Enter Text</Typography>
              <TextField
                variant='outlined'
                multiline
                rows={10}
                fullWidth
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
            </Box>
          );
        default:
          return null;
      }
    } else if (currentStep === 1) {
      return (
        <FormatContainer>
          <FormatTitle>기사 형식 설정</FormatTitle>
          <FormatCards>
            {formatOptions.map((format, idx) => (
              <FormatCard
                key={idx}
                id={idx}
                img={format.icon}
                title={format.title}
                content={format.description}
                selected={format.selected}
                isActivated={format.isActivated}
                onClick={() => handleFormatSelection(format.title)}
              />
            ))}
          </FormatCards>
        </FormatContainer>
      );
    } else if (currentStep === 2) {
      return (
        <>
          <TitleSection>내 규칙 세트</TitleSection>
          <RulesContainer>
            {rulesets?.rulesets?.length > 0 &&
              rulesets?.rulesets?.map((ruleset, idx) => (
                <RuleSetSelect
                  key={idx}
                  category='rulesets'
                  ruleset={ruleset}
                  isSelected={selectedRulesets?.rulesets?.some(
                    (a) => a.id === ruleset.id
                  )}
                  onCardClick={handleRuleSetSelection}
                />
              ))}
          </RulesContainer>

          <TitleSection>공통 규칙 세트</TitleSection>
          <RulesContainer>
            {rulesets?.shared?.length > 0 &&
              rulesets?.shared?.map((ruleset, idx) => (
                <RuleSetSelect
                  key={idx}
                  category='shared'
                  ruleset={ruleset}
                  isSelected={selectedRulesets?.shared?.some(
                    (a) => a.id === ruleset.id
                  )}
                  onCardClick={handleRuleSetSelection}
                />
              ))}
          </RulesContainer>
        </>
      );
    } else if (currentStep === 3) {
      return (
        <>
          <TitleSection>추가 생성 가이드</TitleSection>
          <Box>
            <TextField
              variant='outlined'
              multiline
              rows={5}
              fullWidth
              value={instructions}
              onChange={(e) => setInstructions(e.target.value)}
            />
          </Box>
        </>
      );
    }
  };

  return (
    <Wrapper>
      <Box>
        {renderStepContent()}
        <ButtonContainer>
          <Button
            variant='contained'
            onClick={handleBack}
            disabled={currentStep === 0}
          >
            이전
          </Button>
          <Button variant='contained' color='primary' onClick={handleNext}>
            다음
          </Button>
        </ButtonContainer>
        {popupModalStatus && (
          <PopupModal
            popupModalStatus={popupModalStatus}
            closePopupModal={PopupModal.closePopupModal}
            popupMessage={PopupModal.popupMessage}
            handleConfirmAction={PopupModal.handleConfirmAction}
          />
        )}
      </Box>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px 40px 80px 40px;
`;

const RulesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
  justify-content: flex-start;
`;

const TitleSection = styled.div`
  font-size: 18px;
  font-family: 'Arial';
  color: ${({ theme }) => theme.pb.black};
  margin-bottom: 14px;
  margin-top: 32px;
`;

const ArticleContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
`;

const NewsCardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(580px, 1fr));
  grid-gap: 10px;
  margin-top: 14px;
`;

const ButtonContainer = styled(Box)`
  position: fixed;
  bottom: 40px;
  right: 40px;
  display: flex;
  gap: 10px;
`;

const FormatContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const FormatCards = styled.div`
  display: flex;
  flex-direction: row;
`;

const FormatTitle = styled.div`
  font-size: 18px;
  font-family: 'Arial';
  color: ${({ theme }) => theme.pb.black};
  margin-bottom: 14px;
  margin-top: 32px;
  align-text: left;
`;
